/* eslint-disable max-len */
/* eslint-disable  no-unused-vars */
import React, {useEffect, useMemo, useState} from 'react';
import {
    Box,
    Grid,
    IconButton,
    Typography,
    withStyles,
    Divider,
    Tooltip,
    FormHelperText,
} from '@material-ui/core';
import styles from './styles';
import {useHistory, useLocation} from 'react-router-dom';
import {ArrowBack, HelpOutline} from '@material-ui/icons';
import {MButton, MInput, MSelect} from '../../../../components/form';
import {capitalize as _capitalize} from 'lodash';
import {getImageUrl, isValidURL} from '../../../../helpers';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {MyEditor} from '../../../../components';
import MultiSelectWithModal from './selectedGroup';
import {
  createEventGroup,
  getEventGroupDetail,
  updateEventGroup,
} from '../../../../redux/eventManagement/eventManagement.actions';
import {useDispatch, useSelector} from 'react-redux';

const SUPPORTED_FILE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];


const eventTypeOptions = [
    {id: 'public', name: 'Public'},
    {id: 'private', name: 'Private'},
  ];

const initialForm = {
  title: null,
  description: null,
  events_img: null,
  groups: null,
  type: null,
};

const descriptionValidator = (value) => {
  const result = new DOMParser().parseFromString(value || '', 'text/html')?.body?.textContent?.trim()?.length;
  if (!result) return false;
  return true;
};

const fileSizeValidator = (value) => !value || isValidURL(value) ? true : value.size <= 5000000;
const fileFormatValidator = (value) => !value || isValidURL(value) ? true : SUPPORTED_FILE_FORMATS.includes(value.type);

const eventSchema = {
  title: yup.string().required(),
  description: yup.string().test('not-empty', 'Description is a required field', descriptionValidator).required(),
  type: yup.string().oneOf(eventTypeOptions.map((item) => item.id)).required(),
  groups: yup
  .array().required('At least one event active is required') // Validasi jika array kosong
  .min(1, 'Select at least one active event'),
};

const schemaCreate = yup
  .object({
    ...eventSchema,
    events_img: yup.mixed().required()
      .test('fileSize', 'File size is too large (maximum 5 MB)', fileSizeValidator)
      .test('fileType', 'File type is not supported', fileFormatValidator),
  })
  .required();

function CreateEventGroupPage({classes}) {
    const history = useHistory();
    const {state} = useLocation();
    const locationState = useMemo(() => state ? state : {}, [state]);
    const [formData, setFormData] = useState({...initialForm});
    const [imgPreview, setImgPreview] = useState();
    const dispatch = useDispatch();
    const {
      eventGroupDetail,
    } = useSelector((state) => state.eventManagement);

    const {
        clearErrors,
        formState: {errors: errorForms},
        handleSubmit,
        register,
        setValue,
        getValues,
        reset,
        setError,
      } = useForm({
        resolver: yupResolver(schemaCreate),
        defaultValues: {
          ...initialForm,
        },
      });

    const redirectToEventDashboard = () => {
        if (locationState?.isEdit) {
          history.goBack();
        } else {
          history.push('/event-group');
        }
      };

      const getErrorForm = (key, name = null) => {
        if (!errorForms || !errorForms[key]) return;

        if (!name) {
          name = _capitalize(key.replace('_', ' '));
        }

        return errorForms[key] ? errorForms[key]?.message?.replace(key, name) : '';
      };

      const getBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setImgPreview(reader.result);
        };
        reader.onerror = (error) => {
          console.log('Upload file error: ', error);
        };
      };

      const handleInputFile = (event) => {
        const file = event?.target?.files[0];
        getBase64(file);

        setFormData((prevState) => ({
          ...prevState,
          events_img: file,
        }));

        setValue('events_img', file);
        clearErrors('events_img');
      };


  const handleInputChange = (event) => {
    const {name, value} = event?.target || {};
    setValue(name, value);
    if (value) clearErrors(name);
      setFormData((prevState) => ({
        ...prevState,
          [name]: value,
      }));
    };

    const handleEditorChange = (newValue) => {
        setValue('description', newValue);
        const isEmpty = !(new DOMParser().parseFromString(newValue || '', 'text/html')?.body?.textContent?.trim()?.length);
        if (!isEmpty) clearErrors('description');
        setFormData((prevState) => ({
            ...prevState,
              ['description']: newValue,
          }));
      };


      const onSubmit = (data, status) => {
        if (!data?.events_img) {
          setError('events_img', {message: 'Photo is required', type: 'required'});
          return;
        }

        const dataForm = new FormData();

        Object.keys(data).forEach((key) => {
          if (key === 'groups' && Array.isArray(data[key])) {
            data[key].forEach((group, index) => {
              const groupId = group.id;
              if (groupId) {
                dataForm.append(`groups[${index}]`, groupId);
              }
            });
          } else {
            dataForm.append(key, data[key]);
          }
        });

        if (locationState?.isEdit && isValidURL(data?.events_img)) {
            dataForm.delete('events_img');
        }

        dataForm.append('status', status);

        if (locationState?.isEdit) {
          dispatch(updateEventGroup(eventGroupDetail?.id, dataForm, history));
        } else {
          dispatch(createEventGroup(dataForm, history));
        }
    };

    const {isFormDisabled} = useMemo(() => {
      const isFormDisabled = locationState?.isEdit && eventGroupDetail?.status === 'active';
      return {isFormDisabled};
    }, [locationState, eventGroupDetail]);

    useEffect(() => {
      window.history.replaceState({}, '');
      setImgPreview(null);
      clearErrors();
      reset();
    }, [clearErrors, reset]);


    useEffect(() => {
      if (locationState?.id && locationState?.isEdit) {
        dispatch(getEventGroupDetail(locationState.id));
      } else {
        history.push('/event-group/create');
      }
    }, [history, locationState, dispatch]);

    useEffect(() => {
      if (eventGroupDetail?.id && eventGroupDetail.id === locationState.id) {
        const updateForm = {
          title: eventGroupDetail?.title,
          description: eventGroupDetail?.description,
          type: eventGroupDetail?.type,
          events_img: eventGroupDetail?.photo,
          groups: eventGroupDetail?.group,
        };

        setFormData({...updateForm});
        setImgPreview(getImageUrl(eventGroupDetail?.photo));

        Object.keys(updateForm).map((item) => {
            return setValue(item, updateForm[item]);
        });
      }
    }, [eventGroupDetail, locationState, setValue]);

    return (
        <>
        <Box className={classes.topBox}>
        <IconButton onClick={redirectToEventDashboard}>
          <ArrowBack />
        </IconButton>

        <Typography
          style={{
            fontWeight: 700,
            fontSize: '18px',
          }}
        >
          Event Group List
        </Typography>
      </Box>
      <Box
      className={classes.formContainer}
        style={{
          borderRadius: '16px',
          backgroundColor: '#FFF',
          border: '1px solid #EBEBED',
        }}
      >
        <Box container className={classes.formTitle}>
            <Typography >Event Group</Typography>
            <Box>
            {!locationState?.isEdit || eventGroupDetail?.status !== 'active' ?
            <MButton
                  type="button"
                  onClick={handleSubmit((data) => onSubmit(data, 'draft'))}
                  className={`${classes.btnHeader} ${classes.btnSaveDraft}`}
                  label="Save Draft"
                  loading={false}
                />:
              null
              }
              <MButton
                type="button"
                onClick={handleSubmit((data) => onSubmit(data, 'publish'))}
                className={`${classes.btnHeader} ${classes.btnPublish}`}
                label={isFormDisabled ? 'Update' : 'Publish Group'}
                loading={false}
              />
            </Box>
            </Box>
            <form autoComplete="off">
            <Grid container>
              <MInput
                {...register('title')}
                label="Group Event Name *"
                variant="outlined"
                value={getValues('title')}
                onChange={handleInputChange}
                error={getErrorForm('title', 'Event Group Name')}
                disabled={isFormDisabled}
              />
            </Grid>
            <Grid>
              <MSelect
                {...register('type')}
                classNameFC={classes.formControl}
                label="Event Type *"
                endAdornment={
                  (<IconButton className={classes.searchIcon} position="end">
                    <Tooltip title={'Public means your event will be visible to all of Pagii mobile user across your organization. Private means you have to share the event link manually'}>
                      <HelpOutline fontSize="medium" />
                    </Tooltip>
                  </IconButton>)
                }
                keyPair={['id', 'name']}
                options={eventTypeOptions}
                onChange={handleInputChange}
                value={getValues('type')}
                error={getErrorForm('type', 'Event Group Type')}
                disabled={isFormDisabled}
                shrink
              />
            </Grid>
            <Grid>
              <Grid style={{marginTop: '16px'}}>
                <Typography>Description *</Typography>
              </Grid>

              <Grid>
                <div className={`create-event-editor-wrapper ${errorForms?.description ? classes?.editorWrapperError : classes?.editorWrapper}`}>
                <Grid item xs={11}>
                  <MyEditor
                    value={getValues('description')|| (locationState?.isEdit ? eventGroupDetail?.description : null)}
                    onChange={(value) => handleEditorChange(value)}
                  />
                </Grid>

                <Grid item xs={1} ver>
                  <IconButton className={classes.searchIcon} position="end">
                    <Tooltip title={'Describe information of your event including contact info or social media. You can also attach a link of the event'}>
                      <HelpOutline fontSize="medium" />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </div>

              { errorForms?.description ?
                <FormHelperText style={{color: 'red'}}>
                  {getErrorForm('description')}
                </FormHelperText> : null}
              </Grid>
            </Grid>

            <Grid style={{marginBottom: '16px'}}>
              <MInput
                type="file"
                classNameFC={classes.fileInput}
                label="Photo *"
                name="events_img"
                inputProps={{accept: SUPPORTED_FILE_FORMATS.join(',')}}
                onChange={handleInputFile}
                error={getErrorForm('events_img', 'Photo')}
              />

              <span
                style={{
                  fontStyle: 'italic',
                  fontSize: '12px',
                  color: '#656464',
                }}
              >
                Must be jpg/jpeg/png with minimum resolution 1200 x 800 (Max. File Size 5 MB)
              </span>
            </Grid>

            <Grid style={{marginBottom: '16px'}}>
              {(locationState?.isEdit) || imgPreview ?
                <img
                  loading="lazy"
                  alt={'User uploaded event banner'}
                  src={imgPreview}
                  style={{borderRadius: '6px', width: '100%', height: 'auto', objectFit: 'contain'}}
                /> :
              <span className={classes?.imgPreviewLabel}>
                Uploaded photo will be shown here
              </span>}
            </Grid>

            <Divider />
            <Grid style={{marginBottom: '16px'}}>
                <MultiSelectWithModal
                  handleChange={(event)=>handleInputChange(event)}
                  getError={getErrorForm('groups', 'Event Group Active')}
                  clearErrors={clearErrors}
                  getValues={getValues('groups')}
                />
            </Grid>
            </form>
            </Box>

        </>
    );
}

export default withStyles(styles)(CreateEventGroupPage);
