/* eslint-disable react/display-name */
/* eslint-disable max-len */
import React, {useCallback, useEffect, useState} from 'react';
import {
  IconButton,
  withStyles,
  Paper,
  Grid,
  Button,
  Typography,
  Tooltip,
} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {
  MButton,
  MInput,
  MKeyBoardDatepicker,
  MSelect,
} from '../../../../components/form';
import {Refresh} from '@material-ui/icons';
import styles from './styles';
import {Datatable} from '../../../../components/index.js';
import {convDate} from '../../../../helpers/index.js';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RenderActions from './actionMenu';
import {getEventGroups} from '../../../../redux/eventManagement/eventManagement.actions.js';

const eventTypeOptions = [
  {id: 'private', name: 'Private'},
  {id: 'public', name: 'Public'},
  {id: 'all', name: 'All'},
];

const initialFilter = {
  pagination: true,
  page: 1,
  limit: 10,
  keyword: null,
  type: null,
  start_date: null,
  end_date: null,
};


const renderTooltipDate = (value, text) => {
  return (
    <div className="d-flex" style={{height: '20px'}}>
    <Typography className={{
      fontSize: '18px',
      fontWeight: '700',
      color: '#656464',
      fontFamily: 'Poppins !important',
      marginTop: '-2px !important',
      marginLeft: '-8px !important',
    }}>{value}</Typography>
      <Tooltip title={text}>
        <IconButton className={{fontSize: '14px'}} style={{padding: '5px'}}>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
  </div>
  );
};
const columns = [
  {name: 'id', display: false},
  {name: 'title', label: 'Event Group Name', display: true},
  {
    name: 'date_start',
    label: renderTooltipDate('Start Date', 'This date is automatically taken from the earliest start date among the events listed.'),
    display: true,
    displayName: 'title',
    customBodyRender: (value) => {
      return value ? (
        <Button
          variant="outlined"
          size="small"
          style={{whiteSpace: 'nowrap'}}
        >
          {convDate(value, 'DD-MM-YYYY')}
        </Button>
      ) : (
        '-'
      );
    },
  },
  {
    name: 'date_end',
    label: renderTooltipDate('End Date', 'This date is automatically taken from the latest end date among the events listed'),
    display: true,
    customBodyRender: (value) => {
      return value ? (
        <Button
          variant="outlined"
          size="small"
          style={{whiteSpace: 'nowrap'}}
        >
          {convDate(value, 'DD-MM-YYYY')}
        </Button>
      ) : (
        '-'
      );
    },
  },
  {
    name: 'type',
    label: 'Type',
    display: true,
    customBodyRender: (value) => {
      return <span style={{textTransform: 'capitalize'}}>{value}</span>;
    },
  },
  {
    name: 'status',
    label: 'Status',
    display: true,
    customBodyRender: (value) => {
      return value === 'active' ? (
        <span
          style={{
            color: '#008000',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        >
          published
        </span>
      ) : value === 'inactive' ? (
        <span
          style={{
            color: '#E5AF5A',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        >
          draft
        </span>
      ) : value === 'ended' ? (
        <span
          style={{
            color: '#777777',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        >
          {value?.replace('_', ' ')}
        </span>
      ) : (
        <span
          style={{
            color: '#F22951',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        >
          {value?.replace('_', ' ')}
        </span>
      );
    },
  },
];


function EventGroupList({classes}) {
const dispatch = useDispatch();
const [filterState, setFilterState] = useState({...initialFilter});
const {
  eventGroups,
} = useSelector((state) => state.eventManagement);

const fetchList = useCallback((params = initialFilter) => {
  const newParams = {
    ...initialFilter,
    ...params,
    limit: params?.limit !== initialFilter?.limit ? params?.limit : initialFilter?.limit,
  };

  if (params?.sortBy) {
    delete newParams.sortBy;
    newParams.order = params.sortBy;
    newParams.sort = params.order?.toUpperCase();
  }

  setFilterState({...newParams});
  dispatch(getEventGroups(newParams));
  }, [dispatch]);

  useEffect(() => {
    fetchList();
  }, [dispatch]);


  const renderTableTitle = () => {
    return (
      <div className={classes.MUIDataTableToolbar}>
        <div className="d-flex" style={{height: '20px'}}>
          <Typography className={classes.tableTitle}>Event Group List</Typography>
            <Tooltip title="Organize your events into groups. Only active events can be added to a group">
              <IconButton className={classes.tableTooltip} style={{padding: '5px'}}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
        </div>
      </div>
    );
  };

  const history = useHistory();

  const handleDetail = (id) => {
    if (!id) return;
    history.push('/event-group/detail', {id});
  };

  const handleFilterChange = (event) => {
    const {name, value} = event?.target || {};

    setFilterState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleDateChange = (name, value) => {
    setFilterState((prevState) => ({
      ...prevState,
      [name]: convDate(value, 'YYYY-MM-DD'),
    }));
  };

  const handleApplyFilter = () => {
    if (!filterState?.keyword && !filterState?.type && !filterState?.end_date && !filterState?.start_date) return;
    fetchList({
      ...filterState,
      type: filterState?.type === 'all' ? null : filterState?.type,
    });
  };

  const handleResetFilter = () => {
    if (!filterState?.keyword && !filterState?.type && !filterState?.end_date && !filterState?.start_date) return;
    const newFilter = {
      ...filterState,
      ...initialFilter,
      page: filterState?.page || initialFilter?.page,
      limit: filterState?.limit || initialFilter?.limit,
    };

    fetchList(newFilter);
    setFilterState({...newFilter});
  };

    return (
        <>
        <Paper variant="outlined" className={classes.paper}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <MInput
              autoFocus
              fullWidth
              variant="standard"
              name="keyword"
              label="Search"
              placeholder="Search keyword"
              onChange={handleFilterChange}
              value={filterState?.keyword || ''}
              InputProps={{
                endAdornment: <IconButton className={classes.searchIcon} position="end">
                  <Search/>
                </IconButton>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <MSelect
              shrink={true}
              fullWidth
              variant="standard"
              name="type"
              label="Type"
              keyPair={['id', 'name']}
              options={eventTypeOptions}
              onChange={handleFilterChange}
              value={filterState?.type}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <MKeyBoardDatepicker
              fullWidth
              label="Start Date"
              name="start_date"
              onChange={(value) => handleDateChange('start_date', value)}
              value={filterState?.start_date}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <MKeyBoardDatepicker
              fullWidth
              label="End Date"
              name="end_date"
              minDate={filterState?.start_date}
              onChange={(value) => handleDateChange('end_date', value)}
              value={filterState?.end_date}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" direction="row-reverse">
          <Grid item>
            <MButton
              label="Reset"
              color="primary"
              icon={<Refresh />}
              style={{height: '32px'}}
              onClick={handleResetFilter}
            />
          </Grid>

          <Grid item>
            <MButton
              label="Apply"
              color="primary"
              style={{height: '32px'}}
              onClick={handleApplyFilter}
            />
          </Grid>
        </Grid>
      </Paper>
      <Datatable
        title={renderTableTitle()}
        loading={false}
        dataSource={eventGroups.events ?? []}
        total={0}
        page={initialFilter.page}
        columns={columns}
        customActions={(data) =>
          <RenderActions
            handleDetail={handleDetail}
            handleDeletedEvent={() => console.log('detail')}
            data={data}
            allData={eventGroups.events}
          />
        }
        handleReload={(params) => fetchList({...filterState, ...params})}
        handleCreate={() => history.push('/event-group/create')}
      />
        </>
    );
}

export default withStyles(styles)(EventGroupList);
