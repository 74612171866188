import React, {Component} from 'react';
import {
  withStyles,
} from '@material-ui/core';
import styles from './styles';
import PagiiLogo from '../../../../assets/img/pagii-simple-attendance-solution-logo.png';
import SmooetsLogo from '../../../../assets/img/main-logo-smooets-horizontal-sm.png';
import {convDate} from '../../../../helpers';
import moment from 'moment';

class QREventPage extends Component {
  render() {
    const {classes, data: {organization, event, qr}} = this.props;
    const startDate = moment(event?.startDate);
    const endDate = moment(event?.endDate);
    const diff = startDate.diff(endDate, 'days');

    let formatDate = '';

    if (diff === 0) {
      const startDateStr = convDate(startDate, 'DD MMMM YYYY');
      formatDate = startDateStr;
    } else {
      const startDateStr = convDate(startDate, 'DD MMMM YYYY');
      const endDateStr = convDate(endDate, 'DD MMMM YYYY');
      formatDate = startDateStr + ' - ' + endDateStr;
    }

    return (
      <div>
        <div className={classes.printPage}>
          <div className="print-title">
            <h1 className={classes.printTitle}>Attendance QR</h1>
          </div>

          <div className={classes?.eventWrapper}>
            <h1 className={classes?.eventTitle}>{event?.name}</h1>
            <h1 className={classes?.eventTime}>{formatDate}</h1>
            <h1 className={classes?.eventAddress}>{event?.address}</h1>
          </div>

          <div className="print-qr text-center">
            <img className={classes.printQRImg} src={qr} alt="img" />
            <h3 className={classes.printSubTitle}>Scan Me for Checkin / Checkout</h3>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="">
              <img
                className={`comp any-logo ${classes.printLogo}`}
                src={organization?.logo}
                alt="company-logo"
              />
            </div>

            <div>
              <div className="info-group">
                <label className={`company-label ${classes.printCompanyAddress}`}>
                  {organization?.name}
                </label>
              </div>
              <div className="info-group">
                <label className={`company-label ${classes.printCompanyAddress}`}>
                  {organization?.address}
                </label>
              </div>
            </div>
          </div>

          <div className={classes.printFooter}>
            <div><img src={PagiiLogo} className={classes.pagiiLogo} alt="img" /></div>
            <h5 className={classes.printFooterText}>Powered By:</h5>
            <div><img src={SmooetsLogo} className={classes.smooetsLogo} alt="img" /></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(QREventPage);
