import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CircularProgress, withStyles} from '@material-ui/core';
import styles from './styles';
import {getEventQR} from '../../../../redux/eventManagement/eventManagement.actions';
import {useLocation} from 'react-router-dom';
import {convDate, getImageUrl} from '../../../../helpers';
import moment from 'moment';
import '../../../../assets/style/style.css';
import '../../../../assets/style/mobile.css';

const QREventPage = ({classes}) => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const {eventQR, isLoading} = useSelector((state) => state?.eventManagement);

  const formattedDate = useMemo(() => {
    const startDate = moment(eventQR?.event_date_start);
    const endDate = moment(eventQR?.event_date_end);
    const diff = startDate.diff(endDate, 'days');

    if (diff === 0) {
      return convDate(startDate, 'D MMMM YYYY');
    } else {
      const startDateStr = convDate(startDate, 'D MMMM YYYY');
      const endDateStr = convDate(endDate, 'D MMMM YYYY');
      return startDateStr + ' - ' + endDateStr;
    }
  }, [eventQR]);

  useEffect(() => {
    const id = pathname?.split('/').pop();

    if (id) {
      dispatch(getEventQR(id));
    }
  }, [dispatch, pathname]);

  return (
    <div>
      <section id="section-qr" className={classes.qrSection}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="qr-wrapper">
                <div className="row">
                  <img
                    className={`img-qr ${isLoading && classes.hidden} ${classes.qrCode}`}
                    src={getImageUrl(eventQR?.event_photo)}
                    alt="Event Banner"
                    style={{maxHeight: '400px', marginBottom: '23px'}}
                  />
                </div>

                <div className="row">
                  <div className="col-md-4">
                    {
                      isLoading ?
                        (
                          <div className={classes.loaderContainer}>
                            <div className={classes.loader}>
                              <CircularProgress color="primary" size={24} />
                              <div className={classes.loaderText}>{'Loading QR...'}</div>
                            </div>
                          </div>
                        ) : (
                          <img
                            className={`img-qr ${isLoading && classes.hidden} ${classes.qrCode}`}
                            src={eventQR?.file}
                            alt="QR Code"
                          />
                        )
                    }
                  </div>
                  <div className={`col-md-8 ${classes.companyDetail}`}>
                    <h1 className={`${classes.titleFont}`}>Scan Attendance QR</h1>
                    <div className="company-info">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="info-group">
                            {
                              !eventQR?.organization_logo ? null :
                                (
                                  <img
                                    src={eventQR.organization_logo}
                                    className={`company-logo ${isLoading && classes.hidden}`}
                                    alt="company-logo"
                                  />
                                )
                            }
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="info-group">
                            <label className="company-label">Event Name</label>
                            <span className="company-span">{eventQR?.event_title}</span>
                          </div>
                          <div className="info-group">
                            <label className="company-label">Event Date</label>
                            <span className="company-span">{eventQR?.event_date_start ? formattedDate : ''}</span>
                          </div>
                          <div className="info-group">
                            <label className="company-label">Event Location</label>
                            <span className="company-span">{eventQR?.event_address}</span>
                          </div>
                          <div className="info-group">
                            <label className="company-label">Company Name</label>
                            <span className="company-span">{eventQR?.organization_name}</span>
                          </div>
                          <div className="info-group">
                            <label className="company-label">Company Address</label>
                            <span className="company-span">{eventQR?.organization_address}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default (withStyles(styles))(QREventPage);
