import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {Box} from '@material-ui/core';
import EventGroupList from './list';
import {useSelector} from 'react-redux';
import {checkSuperadmin} from '../../../helpers';
import EventGroupListAdmin from './listAdmin';

function EventGroup({classes}) {
    const {auth} = useSelector((state) => state) || {};
    return (
        <div className={classes.root}>
        <Box>
            {checkSuperadmin(auth) ?
            <EventGroupListAdmin/>:
            <EventGroupList/>
            }
        </Box>
      </div>
    );
}

export default withStyles(styles)(EventGroup);
